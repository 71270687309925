import React, { FC } from "react"
import { Route, Routes } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Home from "./views/Home"
import User from "./views/User"
import Users from "./views/Users"
import Activity from "./views/Activity"
import Dashboard from "./views/Dashboard"
import PromoCodes from "./views/PromoCodes"
import { URL } from "./constants/api/requests"
import Transactions from "./views/Transactions"
import { useProps } from "./contexts/PropsContext"
import NavBar from "./components/navigation/NavBar"
import AuthenticatedRoutes from "./router/AuthenticatedRoutes"
import { Bonueses } from "views/Bonuses"
import { BankTransfers } from "views/BankTransfers"
import CMSPage from "views/CMSPage"
import { KYCFiles } from "views/KYCFiles"

// ci

const App: FC = () => {
  const { darkMode } = useProps()
  const { t } = useTranslation("landing", { useSuspense: false })

  return (
    <div className={darkMode ? "dark" : ""}>
      <div className="w-full h-screen min-h-screen overflow-auto bg-white dark:bg-grey-dark">
        <NavBar>
          <Routes>
            <Route path={URL.HOME} element={<Home />} />
            <Route element={<AuthenticatedRoutes />}>
              <Route path={URL.DASHBOARD} element={<Dashboard />} />
              <Route path={URL.CMS} element={<CMSPage />} />
              <Route path={URL.TRANSACTIONS} element={<Transactions />} />
              <Route path={URL.BANK_TRANSFER} element={<BankTransfers />} />
              <Route path={URL.USERS} element={<Users />} />
              <Route path={URL.USER} element={<User />} />
              <Route path={URL.BONUSES} element={<Bonueses />} />
              <Route path={URL.PROMO_CODES} element={<PromoCodes />} />
              <Route path={URL.ACTIVITY} element={<Activity />} />
              <Route path={URL.KYC_FILES} element={<KYCFiles />} />
            </Route>
            <Route path={URL.NOT_FOUND} element={<div>{t("not_found")}</div>} />
          </Routes>
        </NavBar>
      </div>
    </div>
  )
}

export default App
