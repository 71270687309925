import { useEffect, useState } from "react"
import { GetSingleUser } from "services/user/type"
import { formatFiatCurrency } from "util/formatter"
import { CardWrapper } from "./CardWrapper"
import userService from "services/user/user.service"

type UserWagerCardProps = {
  user: GetSingleUser
}
export const UserWagerCard = ({ user }: UserWagerCardProps) => {
  const [userBonus, setUserBonus] = useState(0)

  useEffect(() => {
    onLoad()
  }, [])

  const onLoad = async () => {
    const tUserBonus = await userService.getUserTotalBonus(user.id)
    if (tUserBonus) {
      setUserBonus(tUserBonus.total_bonus)
    }
  }

  return (
    <CardWrapper>
      <span>
        Deposit: <span className="text-accent"> {formatFiatCurrency(user.total_deposit_amount)}</span>
      </span>
      <span>
        Bonus: <span className="text-accent">{formatFiatCurrency(userBonus)}</span>
      </span>
      <span>
        Wager: <span className="text-accent">{user.wager}</span>
      </span>
      {/* <span>
        Wins: <span className="text-accent">{user.wins}</span>
      </span> */}
      <span>
        Withdrawal: <span className="text-accent"> {formatFiatCurrency(user.total_withdrawal_amount)}</span>
      </span>
      <span>
        Rewards: <span className="text-accent"> {formatFiatCurrency(user.total_reward_amount)}</span>
      </span>
      <span>
        PNL: <span className="text-accent"> {formatFiatCurrency(user.total_pnl)}</span>
      </span>
      <span>
        Referrals: <span className="text-accent">{user.referrals}</span>
      </span>
      <span>
        30D Wager: <span className="text-accent"> {formatFiatCurrency(user.last30DaysWagered)}</span>
      </span>
      <span>
        30D Deposits: <span className="text-accent"> {formatFiatCurrency(user.last30DaysDeposits)}</span>
      </span>
      <span>
        7D PNL: <span className="text-accent"> {formatFiatCurrency(user.last7DaysPNL)}</span>
      </span>
      {/* <span>
        Affiliate boost: <span className="text-accent">{user.influencer_percent || 50}</span>
      </span>
      <span>
        Affiliate pay percent: <span className="text-accent">{user.affiliate_percent}</span>
      </span> */}
    </CardWrapper>
  )
}
