import axios from "axios"
import clsx from "clsx"
import { ChangeEvent, FC, useEffect, useState } from "react"
import { CMSService } from "services/cms/cms.service"
import { v4 as uuid } from "uuid"

interface IProps {
  id?: string
  title?: string
  invalid?: boolean
  setFieldValue?: any
  placeholder?: string
  disabled?: boolean
  name?: string
  altLabelOne?: string
  altLabelTwo?: string
  altLabelThree?: string
}

const FileUpload: FC<IProps> = (props) => {
  const { id, title, invalid, setFieldValue, placeholder, disabled, name, altLabelOne, altLabelTwo, altLabelThree } = props
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState("")

  useEffect(() => {
    setValue("")
  }, [id])

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      try {
        setLoading(true)
        const file = e.currentTarget.files[0]
        await uploadFile(file)
      } catch (err) {
        console.error("Debug handleUploadFile", err)
      } finally {
        setLoading(false)
      }
    }
  }

  const uploadFile = async (file: File) => {
    const extension = file.name.split(".").pop()
    const fileName = `${uuid()}.${extension}`
    const preSignResponse = await CMSService.presignedUrlForCMSImage(fileName)
    if (!preSignResponse) return
    const { url } = preSignResponse
    if (!url) return

    await axios.put(url, file, {
      headers: {
        Accept: "*/*",
        "Content-Type": file.type
      },
      onUploadProgress: (e) => {
        if (e.loaded === e.total) {
          setLoading(false)
        }
      }
    })

    const uploadedURL = `https://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}.s3.amazonaws.com/cms_banners/${fileName}`
    setFieldValue(id, uploadedURL)
  }

  return (
    <div className="w-full form-control">
      {Boolean(title) && (
        <div className="flex w-full justify-between items-center">
          <label className="label flex w-full justify-between">
            <span className={clsx("label-text", { "text-error": invalid })}>{title}</span>
            <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelOne}</span>
          </label>
          {loading && <div className="loading loading-spinner loading-xs inline-flex"></div>}
        </div>
      )}
      <input
        type="file"
        value={value}
        onChange={handleUploadFile}
        placeholder={placeholder}
        className={`w-full file-input file-input-bordered ${invalid ? "input-error" : ""}`}
        disabled={disabled}
        accept="image/*"
        {...(id && { id })}
        {...(name && { name })}
      />
      <label>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelTwo}</span>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelThree}</span>
      </label>
    </div>
  )
}

export default FileUpload
