import { PhotoView } from "react-photo-view"

type Props = {
  title: string
  path: string
}

const FileViewer = ({ title, path }: Props) => {
  const imageRules = path?.match(/\.(jpg|jpeg|png|gif|webp)$/i)
  const awsS3Rules = path?.includes("X-Amz-SignedHeaders") && path?.includes(String(process.env.REACT_APP_AWS_S3_BUCKET_NAME))
  const isShowImage = imageRules || awsS3Rules

  return (
    <div className="w-full form-control">
      <label className="label">
        <span className="label-text">{title}</span>
      </label>
      {path ? (
        isShowImage ? (
          <div className="h-20 cursor-pointer">
            <PhotoView src={path}>
              <img className="w-20 h-20 mx-auto" src={path} alt="IDCard Back" />
            </PhotoView>
          </div>
        ) : (
          <div className="cursor-pointer" onClick={() => window.open(path, "_blank")}>
            Click here
          </div>
        )
      ) : null}
    </div>
  )
}

export default FileViewer
