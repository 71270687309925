import { REQ } from "constants/api/requests"
import { IResponse } from "constants/interfaces"
import useApi from "util/api"

import { GetKYCFilesResponse, IKYCService, KYCReadAccessParams } from "./type"

export const kycImageKeys = ["img_idcard_front", "img_idcard_back", "doc_proof_address", "doc_add_verify"]

class KYCService implements IKYCService {
  public getKYCFilesList = async (
    page: number,
    pageSize: number,
    id: number | undefined,
    { status, createdAt }: { status?: string; createdAt?: (Date | null)[] },
    sortRow?: string,
    sortOrder?: string
  ): Promise<GetKYCFilesResponse | void> => {
    const params = {
      page,
      pageSize,
      sortRow,
      sortOrder,
      status: status || null,
      createdAt: createdAt || null
    }

    const { data } = await useApi.get<IResponse<GetKYCFilesResponse>>(REQ.ADMIN_DASHBOARD.KYC_FILES.GET_ALL, { params })

    if (data?.success) {
      return data?.response
    }
  }

  public async getKYCFiles(user_id: string): Promise<any | void> {
    const params = { user_id }

    const { data } = await useApi.get<IResponse<any>>(REQ.ADMIN_DASHBOARD.KYC_FILES.GET, { params })

    if (data?.success) {
      const item = data?.response.data
      const userId = item?.user_id
      const kycFiles = item
      const urls = await this.loadKYCSignImages(userId, kycFiles)
      data.response.data.kycSignedImages = urls
    }

    return data?.response
  }
  public async updateKYCFilesStatus(id: string, status: string, reason?: string): Promise<any> {
    const { data } = await useApi.post(REQ.ADMIN_DASHBOARD.KYC_FILES.UPDATE, { id, status, reason })

    if (data?.success) {
      return data?.response
    }
  }

  public async getKYCFilesCount(): Promise<any> {
    const { data } = await useApi.get(REQ.ADMIN_DASHBOARD.KYC_FILES.PENDING_COUNT)

    if (data?.success) {
      return data?.response
    }
  }

  public async getDuplicateRecord(user_id: string): Promise<any | void> {
    const params = { user_id }

    const { data } = await useApi.get<IResponse<any>>(REQ.ADMIN_DASHBOARD.KYC_FILES.CHECK_DUPLICATE, { params })

    if (data?.success) {
      return data?.response
    }
  }

  getReadPresignedUrlKYC = async (userId: string, filenames: string[]): Promise<KYCReadAccessParams[] | void> => {
    const { data } = await useApi.post<IResponse<KYCReadAccessParams[]>>(REQ.ADMIN_DASHBOARD.KYC_FILES.KYC_READ, {
      userId,
      filenames
    })
    if (data.success) {
      return data?.response
    }
  }

  loadKYCSignImages = async (user_id: string, kyc_files: any = []) => {
    const files = kyc_files || []
    const fileNames: string[] = []
    for (const k of kycImageKeys) {
      if (files[k]) {
        const filename = kyc_files[k].split("/").pop()
        fileNames.push(filename)
      }
    }
    const signed = await this.getReadPresignedUrlKYC(user_id, fileNames)
    if (!signed) return

    const signedUrls: { [fileField: string]: string } = {}
    for (const imageKey of kycImageKeys) {
      if (!kyc_files[imageKey]) continue
      const filename = kyc_files[imageKey].split("/").pop()
      const found = signed.find((s) => s.filename === filename)
      if (found) signedUrls[imageKey] = found.url
    }

    return signedUrls
  }
}

export default new KYCService()
