import "react-photo-view/dist/react-photo-view.css"

import FileViewer from "components/fileviewer/FileViewer"
import InputField from "components/inputs/InputField"
import { URL } from "constants/api/requests"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PhotoProvider } from "react-photo-view"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import kycService from "services/kyc/kyc.service"

import Modal from "./Modal"

interface IProps {
  data?: string
  open: boolean
  handler: any
  declineHandler: any
  approveHandler: any
}

const ModalKYCFiles: FC<IProps> = (props) => {
  const navigate = useNavigate()
  const { data: user_id, open, handler, declineHandler, approveHandler } = props
  const { t }: { t: any } = useTranslation(["landing"], { useSuspense: false })
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    if (user_id) {
      getKYCFilesData(user_id)
      checkDuplicate(user_id)
    }
  }, [user_id])

  const checkDuplicate = async (user_id: string) => {
    const { duplicate } = await kycService.getDuplicateRecord(user_id)
    if (duplicate) {
      toast.info("Duplicates detected")
    }
  }

  const getKYCFilesData = async (user_id: string) => {
    const { data: kycData } = await kycService.getKYCFiles(user_id)
    if (kycData) setData(kycData)
  }

  return (
    <Modal open={open} handler={handler}>
      <div className="relative flex flex-col justify-between modal-box max-w-full">
        <PhotoProvider>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <InputField title="First Name" value={data?.user.personal_profile.first_name} disabled />
            <InputField title="Last Name" value={data?.user.personal_profile.last_name} disabled />
            <InputField title="Birthday" value={data?.user.personal_profile.dob} disabled />
            <InputField title="Country" value={data?.user.personal_profile.country} disabled />
            <InputField title="Postcode" value={data?.user.personal_profile.zip} disabled />
            <InputField title="City" value={data?.user.personal_profile.city} disabled />
            <InputField title="Address" value={data?.user.personal_profile.address} disabled />
            <InputField title="Address2" value={data?.user.personal_profile.address2} disabled />

            <FileViewer title="IDCard Front" path={data?.kycSignedImages.img_idcard_front} />
            <FileViewer title="IDCard Back" path={data?.kycSignedImages.img_idcard_back} />
            <FileViewer title="Proof Address" path={data?.kycSignedImages.doc_proof_address} />
            <FileViewer title="Additional Document" path={data?.kycSignedImages.doc_add_verify} />

            <div className="w-full form-control text-left">
              <div
                className="cursor-pointer font-semibold"
                onClick={() => {
                  if (data?.user_id) {
                    navigate(URL.USER.replace(":id", data.user_id))
                  }
                }}
              >
                View Profile
              </div>
            </div>
          </div>
          <div className="modal-action">
            <button
              className="btn btn-primary"
              onClick={() => {
                handler()
                declineHandler(data)
              }}
            >
              {t("decline")}
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                handler()
                approveHandler(data)
              }}
            >
              Approve
            </button>
          </div>
        </PhotoProvider>
      </div>
    </Modal>
  )
}

export default ModalKYCFiles
