import { GetSingleUser } from "services/user/type"
import { formatBoolean, formatDate } from "util/formatter"
import { toast } from "react-toastify"
import { CardWrapper } from "./CardWrapper"
import { cardGatewayTitles } from "constants/deposit-gateway"
import { CardGateway } from "types/api"
import { useState } from "react"
import ModalKYCFiles from "components/modals/ModalKYCFiles"
import KYCService from "services/kyc/kyc.service"
import { useTranslation } from "react-i18next"
import ModalConfirmAction from "components/modals/ModalConfirmAction"
import { ModalDeclineAction } from "components/modals/ModalDeclineAction"
import { useProps } from "contexts/PropsContext"

type UserGeneralCardProps = {
  user: GetSingleUser
}

enum Tabs {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED"
}

export const UserGeneralCard = ({ user }: UserGeneralCardProps) => {
  const { t }: { t: any } = useTranslation(["landing"], { useSuspense: false })
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [declineRow, setDeclineRow] = useState<any>(null)
  const [approveRow, setApproveRow] = useState<any>(null)
  const { onKYCFilesCount } = useProps()

  const editRowHandler = (): void => {
    setOpenEditModal(false)
  }

  const onOpenApproveRow = (row: any): void => {
    setApproveRow(row)
  }

  const approveRowHandler = (): void => {
    setApproveRow(null)
  }

  const onApprove = async (tx: any): Promise<void> => {
    if (!tx?.id) return

    try {
      const response = await KYCService.updateKYCFilesStatus(tx.id, Tabs.COMPLETED)
      if (response) {
        // @ts-ignore
        onKYCFilesCount()
        toast.success(t("success_action_kyc_files", { actionText: "Approved" }))
      }
    } catch (err: any) {
      toast.error(err?.message || "Failed")
    }
  }

  const onOpenDeclineRow = (row: any): void => {
    setDeclineRow(row)
  }

  const declineRowHandler = (): void => {
    setDeclineRow(null)
  }

  const onDecline = async (rowId: string, reason: string): Promise<void> => {
    const response = await KYCService.updateKYCFilesStatus(rowId, Tabs.DECLINED, reason)
    if (response) {
      // @ts-ignore
      onKYCFilesCount()
      toast.success(t("success_action_kyc_files", { actionText: t("declined") }))
    }
  }

  const handleShowKYCModal = () => {
    setOpenEditModal(true)
  }

  return (
    <CardWrapper>
      <span>
        ID: <span className="text-accent">{user.id}</span>
      </span>
      <span>
        Tenant: <span className="text-accent">{user.tenant_id}</span>
      </span>
      {/* <span>
        Discord: <span className="text-accent">{user.discord_name}</span>
      </span> */}
      <span>
        Email: <span className="text-accent">{user.email_address}</span>
      </span>
      <span>
        VIP level: <span className="text-accent">{user.level}</span>
      </span>
      <span>
        Role: <span className="text-accent">{user.role}</span>
      </span>
      <span>
        Is referred: <span className="text-accent">{formatBoolean(user.is_referred)}</span>
      </span>
      <span>
        Created at: <span className="text-accent">{formatDate(user.created_at)}</span>
      </span>
      <span>
        KYC: <span className="text-accent">{formatBoolean(user.kyc)}</span> {user.kyc ? (<span className="cursor-pointer" onClick={handleShowKYCModal}>(Check Documents)</span>) : null}
      </span>
      <span>
        Deposit Gateway: <span className="text-accent">{cardGatewayTitles[user.next_card_gateway ?? CardGateway.SP]}</span>
      </span>

        <ModalConfirmAction
          data={approveRow}
          handler={approveRowHandler}
          text={t("modal_conformation_kyc_files", {
            actionText: "approve",
            user: approveRow?.user.username
          })}
          actionText="Approve"
          actionHandler={onApprove}
        />
        <ModalDeclineAction
          data={declineRow}
          handler={declineRowHandler}
          text={t("modal_conformation_kyc_files", {
            actionText: "decline",
            user: declineRow?.user.username
          })}
          actionText={t("decline")}
          actionHandler={onDecline}
        />

      <ModalKYCFiles
          open={openEditModal}
          data={user.id}
          handler={editRowHandler}
          approveHandler={onOpenApproveRow}
          declineHandler={onOpenDeclineRow}
        />
    </CardWrapper>
  )
}
