import { FC, useEffect, useState } from "react"
import DatePicker from "react-multi-date-picker"

type Props = {
    id?: string
    title: string
    invalid?: boolean
    altLabelOne?: string
    altLabelTwo?: string
    altLabelThree?: string
    value?: string[]
    onChange?: any
    data?: any
}

const MultiDatesPicker: FC<Props> = (props) => {
    const { id, title, invalid, altLabelOne, altLabelTwo, altLabelThree, onChange, data } = props
    const [selDates, setSelDates] = useState<any>([])
    
    useEffect(() => {
        if (id && data?.[id]) {
            setSelDates(data[id].split(',').map((item: any) => new Date(`${item} 00:00:00`)))
        }
    }, [data, id])

    const handleChangeValues = (dates: any) => {
        setSelDates(dates)
        onChange(id, dates.map((date: any) => date.format('YYYY-MM-DD')))
    }

    return (
        <div className="w-full form-control">
            <label className="label">
                <span className={`label-text ${invalid ? "text-error" : ""}`}>{title}</span>
                <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelOne}</span>
            </label>
            <DatePicker
                containerClassName="w-full !h-[46px] input input-bordered px-0"
                multiple
                value={selDates}
                onChange={handleChangeValues}
                {...(id && { id })}
            />
            <label>
                <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelTwo}</span>
                <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelThree}</span>
            </label>
        </div>
    )
}

export default MultiDatesPicker
